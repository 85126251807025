import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/blocks/AccordionList.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/blocks/ContactList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentNavList"] */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/blocks/ContentNavList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExpandableButton"] */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/blocks/ExpandableButton.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/blocks/FaqList.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/blocks/HeroBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImageBlock"] */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/blocks/ImageBlock.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/blocks/LatestNewsList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ListBlock","OrderedListBlock"] */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/blocks/ListBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsentChangeButton"] */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/component/cookieConsent/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/component-library/movestic-core/tailwind/Breadcrumb.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MonthSavingsCalculation"] */ "/home/vsts/work/1/s/component-library/movestic-core/tailwind/MonthSavingsCalculation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SalaryTaxExchange"] */ "/home/vsts/work/1/s/component-library/movestic-core/tailwind/SalaryTaxExchange.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/node_modules/react-inlinesvg/dist/index.mjs");
